import React from "react"
import LayoutComponent from "../../components/Layout.js"
import MRSkan from "../../components/MR.js"
import Knap from "../../components/Knap.js"

const MRSkanningPage = () => (
    <LayoutComponent>
        <h1>MR Scanning</h1>
        <p>Utöver vårt stora val inom röntgentillbehör är vi också leveransduktiga av tillbehör till magnetisk resonans/MR-scanning: Här har vi tagit ut några av våra leverantörer, som har fokus på kvalitetskontroll (QA) och träningsfantomer, men också rullstolar, sängar, sängtillbehör, droppstativ, papperskorgar, avfallssortering, stolar, fotpallar, antimagnetiska inventarier, gåstolar och brandsläckare.</p>
        <h1>Våra leverantörer</h1>
        <MRSkan />
        <Knap />
    </LayoutComponent>
)
export default MRSkanningPage